/**
 * Created by joerg on 5/3/17.
 */

// angular
import { NgModule, ModuleWithProviders } from '@angular/core';

// mn
import {MnCoreModule} from "@mn/core"

import {MnGridLazy} from "./MnGridLazy";

@NgModule({
    imports: [
        MnCoreModule,
    ],
    exports: [
        MnGridLazy
    ],
    declarations: [
        MnGridLazy
    ],
    entryComponents: [
        MnGridLazy
    ]
})
export class MnGridLazyModule {
    static forRoot(): MnGridLazyModule {
        return {
            ngModule: MnGridLazyModule
            //providers: []
        };
    }
}

export class mn_gridlazy {
    static configure():any {
        return [
            MnGridLazyModule.forRoot()
            /*MnRouteModule.forRoot(),
            UIRouterModule.forRoot({
                states: [],
                useHash: true
            })*/
        ];
    }
}