/**
 * Created by Joerg on 15.08.2017.
 */

import {Subject,Subscription} from 'rxjs/Rx';

import {Component,Injectable,OnDestroy} from "@angular/core";

@Injectable()
export class MnGridParams {

    private mChange:Subject<any> = new Subject();
    private mParams:any;

    constructor() {};

    public get() {
        return this.mParams;
    }

    public next(value:any) {
        this.mParams = value;
        this.mChange.next(value);
    }

    public subscribe(callback:any):Subscription {
        return this.mChange.subscribe(callback);
    }
}

@Component({
    selector: 'mn-grid-lazy',
    template: `<mn-lazy [comp]="mParams.colDef.lazy"></mn-lazy>`,
    providers: [MnGridParams]
})
export class MnGridLazy {

    private mParams:any;

    constructor(private mParamsService:MnGridParams) {}

    agInit(params:any): void {
        console.log("agInit",params);
        this.mParams = params;
        this.mParamsService.next(params);
    }

    refresh(params: any): boolean {
        console.log("refresh",params);
        this.mParams = params;
        this.mParamsService.next(params);
        return true;
    }

    ngOnDestroy() {
        console.log(`Destroying lazy component`);
    }

}

